@import url('https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&display=swap');
$font-family-sans-serif: 'Muli',
-apple-system,
BlinkMacSystemFont,
'Segoe UI',
Roboto,
'Helvetica Neue',
Arial,
'Noto Sans',
sans-serif,
'Apple Color Emoji',
'Segoe UI Emoji',
'Segoe UI Symbol',
'Noto Color Emoji' !default;
$font-family-monospace: SFMono-Regular,
Menlo,
Monaco,
Consolas,
'Liberation Mono',
'Courier New',
monospace;
$body-bg: #f6f6f6;
$body-color: #333333;
$input-bg: #fcfcfc;
$card-border-color: #f0f2f6;
$list-group-border-color: #f0f2f6;
$input-font-size: 0.85rem;
$input-color: #444d56;
$input-placeholder-color: #b5babe;
$enable-responsive-font-sizes: true;
$primary: #4b55e0;
$font-size-base: 0.85rem;
@import '../node_modules/bootstrap/scss/bootstrap';
.icon-input {
    position: relative;
}

svg.feather {
    stroke-width: 2.2px;
    color: #525f70;
    fill: rgba(216, 221, 255, 0.37);
    width: 15px;
    height: 15px;
    margin-top: -3px;
}

.icon-input svg.feather {
    position: absolute;
    left: 11px;
    top: 11px;
    margin-top: 0px;
}

.icon-input .form-control,
.icon-input .bootstrap-tagsinput {
    padding-left: 35px;
}

.icon-input .btn {
    position: absolute;
    right: 10px;
    margin-top: 0px;
    top: 0px;
}

.btn-darkgreen {
    background-color: #073134;
    border-color: #073134;
    color: #ffffff;
    text-transform: uppercase;
    &:hover,
    &:focus {
        color: rgba(255, 255, 255, 0.8);
    }
}

.flex-between {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

header {
    background-color: #073134;
}

.btn-primary,
.bootstrap-tagsinput .tag {
    color: #ffffff;
    background-color: #4b55e0;
    border-color: #4b55e0;
}

.btn,
.bootstrap-tagsinput .tag {
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.3px;
}

.h-100v {
    height: 100vh !important;
}

.avatar {
    position: relative;
    height: 34px;
    min-height: 34px;
    width: 34px;
    min-width: 34px;
}

.avatar .avatar-initial {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #f0f2f6;
    color: #222222;
}

.avatar .avatar-initial {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50% !important;
}

.project-heading-top,
.project-heading-top {
    background-color: #073134;
}

.avatar-lg {
    height: 68px !important;
    min-height: 68px !important;
    width: 68px !important;
    min-width: 68px !important;
}

.avatar-lg .avatar-initial {
    font-size: 30px;
}

.org-heading h5 {
    color: white;
}

.org-heading p {
    color: #d9f8fa;
}

.sort-wrapper {
    overflow-y: auto;
    flex-wrap: nowrap;
}

.nav-pills .nav-link.active,
.nav-pills .nav-link:hover,
.nav-pills .nav-link:focus {
    background-color: #0e646a;
    border-radius: 150px;
}

.nav-pills .nav-link.active svg,
.nav-pills .nav-link:hover svg,
.nav-pills .nav-link:focus svg {
    color: #ffffff;
}

.nav-pills .nav-link {
    padding: 0.4rem 0.85rem;
    margin-right: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    border-radius: 150px;
}

.nav-pills .nav-link svg {
    fill: transparent;
    margin-top: 0px;
    margin-right: 5px;
}

.nav-pills .nav-link {
    color: #d9f8fa;
    border-radius: 150px;
}

.nav-pills .nav-link svg {
    color: #d9f8fa;
}

.loader,
.loader:before,
.loader:after {
    background: #123235;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}

.loader {
    color: #123235;
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    position: absolute;
    top: 0;
    content: '';
}

.loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loader:after {
    left: 1.5em;
}

@-webkit-keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

.autocomplete {
    position: relative;
    display: inline-block;
}

.autocompleteItems {
    position: absolute;
    border: 1px solid #d4d4d4;
    background-color: #ffffff;
    padding: 0;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    top: 100%;
    left: 0;
    right: 0;
}

.autocomplete-active {
    background-color: rgba(216, 221, 255, 0.37) !important;
}

.autocompleteItems .link {
    display: block;
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
    &:hover {
        background-color: rgba(216, 221, 255, 0.37);
    }
}

.tools-search {
    border: none;
    background: #ffffff;
    &:focus {
        outline: none !important;
        box-shadow: none;
    }
}

.search-icon {
    width: 30px !important;
    height: 30px !important;
    top: 5px !important;
    fill: none !important;
    color: #aaaeb4 !important;
}

.border-rounded-corner {
    border-radius: 1.25rem !important;
}

.search-width {
    width: 50%;
}

.status {
    position: absolute;
    right: 10px;
    font-size: 12px;
    padding: 6px;
}

.line-seperator {
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.comment-box {
    background: #ffffff;
    height: auto;
    width: 100%;
    overflow: hidden;
    overflow-wrap: break-word;
    resize: none;
}

.swal-footer {
    background-color: rgb(245, 248, 250);
    margin-top: 32px;
    border-top: 1px solid #e9eef1;
    overflow: hidden;
    text-align: center;
}

.comment-box {
    background-color: #ffffff;
}

.dropdown {
    position: relative;
}

.cp {
    cursor: pointer !important;
}

.small-heading,
.btn,
.bootstrap-tagsinput .tag {
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.3px;
}

.reply-wrapper {
    display: none;
}

.hold-table {
    tr:nth-child(1) {
        th {
            padding: 20px 0;
        }
        th:nth-child(1) {
            width: 50px;
            padding: 20px 40px;
        }
        th:nth-child(2) {
            min-width: 150px;
            max-width: 200px;
        }
        th:nth-child(3) {
            min-width: 350px;
            max-width: 400px;
            padding-left: 10px;
        }
        th:nth-child(5) {
            min-width: 150px;
            max-width: 250px;
        }
        background-color: #fafbff;
    }
    tr {
        td:nth-child(1) {
            padding: 20px 40px;
            width: 50px;
        }
        td:nth-child(2) {
            min-width: 150px;
            max-width: 200px;
        }
        td:nth-child(3) {
            min-width: 350px;
            max-width: 400px;
            padding-right: 30px;
            padding-left: 10px;
        }
        td:nth-child(4) {
            min-width: 100px;
            max-width: 150px;
        }
        td:nth-child(5) {
            min-width: 150px;
            max-width: 250px;
        }
        td {
            padding: 20px 0;
        }
    }
}

.dashboard-border-rounded {
    border-radius: 15px;
}

@include media-breakpoint-down(lg) {
    .search-width {
        width: 75% !important;
    }
}

@include media-breakpoint-down(md) {
    .search-width {
        width: 95% !important;
    }
}